import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../../components/layout";
import { IoLogoFacebook, IoLogoTwitter, IoLogoYoutube } from "react-icons/io";
export const _frontmatter = {
  "title": "From the Web"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`COVID series on the AFPI Karnataka YouTube channel`}</h2>
    <h3>{`Home Isolation | Dr Sowmya Vivek`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/VsebJjMjoA8" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h3>{`COVID-19 and the Elderly | Dr (Col) Mohan Kubendra`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/QsZYRfYjrdk" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h3>{`Management of Home Isolated COVID positive patients by Family Physicians | Facebook Live`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/zOIkGXacbTc" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h3>{`Palliative Care Amidst COVID crisis | Facebook Live`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/AYmaX8edSyo" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h3>{`Webinar on COVID with comorbidities, complications, and post-infection sequelae`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/GipBc7xOPjM" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2>{`Webinars`}</h2>
    <h3>{`Adult Vaccination | Dr Swapna Bhaskar`}</h3>
    <iframe width="560" height="315" src="https://www.youtube.com/embed/CYeXZUoxt20" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
    <h2>{`Follow AFPI Karnataka`}</h2>
    <a href="https://twitter.com/AFPIKarnataka"><IoLogoTwitter size={56} mdxType="IoLogoTwitter" />/AFPIKarnataka</a>
    <br />
    <a href="https://facebook.com/AFPIKarnataka"><IoLogoFacebook size={56} mdxType="IoLogoFacebook" />/AFPIKarnataka</a>
    <br />
    <a href="https://www.youtube.com/channel/UCSLo6ov-zltSnUCYzXpP_cg"><IoLogoYoutube size={56} mdxType="IoLogoYoutube" /> Youtube</a>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      